import {ActivatedRouteSnapshot, CanActivateFn} from '@angular/router';
import {inject} from '@angular/core';
import {TfAccessTokenService} from '../services/access-token.service';
import {TfAccessTokenHttpService} from '../services/acess-token-http.service';
import {ToastController} from '@ionic/angular';
import {Router} from '@angular/router';
import {firstValueFrom} from "rxjs";
import {CODE_CHALLENGE_NAME, MESSAGE_PARAM_NAME} from "../app.constants";

export const canLogout: CanActivateFn = async (
  route: ActivatedRouteSnapshot
): Promise<boolean> => {
  const accessTokenService = inject(TfAccessTokenService);
  const accessTokenHttpService = inject(TfAccessTokenHttpService);
  const router = inject(Router);

  if (route.queryParams[MESSAGE_PARAM_NAME]) {
    await presentToast(inject(ToastController), route.queryParams[MESSAGE_PARAM_NAME]);
  }

  if (!accessTokenService.exist) {
    await router.navigateByUrl('/');
    return false;
  }

  try {
    await firstValueFrom(accessTokenHttpService.delete$());
    accessTokenService.destroy();

    await router.navigateByUrl(`/?code=${route.queryParams[CODE_CHALLENGE_NAME]}`);
  } catch (e: any) {
    accessTokenService.destroy();
    await router.navigateByUrl(`/?code=${route.queryParams[CODE_CHALLENGE_NAME]}`);
  }

  return false;
};

async function presentToast(
  toastController: ToastController,
  message: string
) {
  const toast = await toastController.create({message, duration: 3e3});
  await toast.present();
}
