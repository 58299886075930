import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAuthResetResponse, IResetPasswordData } from '../types';

@Injectable({
  providedIn: 'root'
})
export class TfUserPasswordService {
  public constructor(private readonly http: HttpClient) {}

  public sendResetLink$(resetInfo: {
    email: string;
  }): Observable<HttpResponse<IAuthResetResponse>> {
    return this.http.post<IAuthResetResponse>('api/v2/forgot_password', resetInfo, {
      observe: 'response',
    });
  }

  public reset$(data: IResetPasswordData): Observable<void> {
    return this.http.post<void>('api/v2/reset_password', data);
  }
}
