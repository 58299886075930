import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export const MIN_PASSWORD_LENGTH = 6;

// @ts-ignore
export const PASSWORD_EQUALITY_FORM_VALIDATOR: ValidatorFn = (
  form: FormGroup
): ValidationErrors | null =>
  form.controls['password'].value !== form.controls['passwordConfirmation'].value
    ? { mismatch: true }
    : null;
