import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'tf-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public hasContent: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  public ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        this.hasContent = this.hasActiveChild(this.activatedRoute);
      }
    });
  }

  private hasActiveChild(route: ActivatedRoute): boolean {
    if (route.firstChild) {
      return this.hasActiveChild(route.firstChild);
    }
    return route.outlet === 'primary' && !!route.component;
  }
}
