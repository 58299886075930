import { InjectionToken } from "@angular/core";

export const ROUTE_PATHS = {
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  LOGOUT: 'logout',
};

export const RESET_PASSWORD_TOKEN_PARAM_NAME = 'token';

export const API_URL_TOKEN = new InjectionToken('Api url token');
export const ENV_TOKEN = new InjectionToken('Active env token');
