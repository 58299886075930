import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {
  MIN_PASSWORD_LENGTH,
  PASSWORD_EQUALITY_FORM_VALIDATOR,
} from './reset-password-page.constants';
import { IResetPasswordData } from '../types';
import { TfUserPasswordService } from '../services/user-password.service';
import { TfAutoUnsubscribeComponent } from "../auto-unsubscribe-component";
import { $localize } from "@angular/localize/init";
import { ToastController } from "@ionic/angular";
import { RESET_PASSWORD_TOKEN_PARAM_NAME } from '../app.constants';

@Component({
  selector: 'am-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
})
export class TfResetPasswordPageComponent extends TfAutoUnsubscribeComponent {
  public isResetting = false;

  public form: FormGroup = this.formBuilder.group(
    {
      password: ['', [Validators.required, Validators.minLength(MIN_PASSWORD_LENGTH)]],
      passwordConfirmation: ['', [Validators.required, Validators.minLength(MIN_PASSWORD_LENGTH)]],
    },
    {
      validators: PASSWORD_EQUALITY_FORM_VALIDATOR,
    }
  );

  public isPasswordExposed = false;

  public constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userPasswordService: TfUserPasswordService,
    private readonly toastController: ToastController
  ) {
    super();
  }

  public reset(): void {
    try {
      this.validateForm();

      this.form.disable();
      this.isResetting = true;

      const resetPasswordData: IResetPasswordData = {
        ...this.form.value,
        resetPasswordToken: this.token,
      };

      this.userPasswordService
        .reset$(resetPasswordData)
        .pipe(takeUntil(this.destroy$$))
        .subscribe(
          () => {

            this.toastController.create({message: $localize`:@@RESET_PASSWORD_PAGE.PASSWORD_SUCCESSFULLY_UPDATED`}).then(async toast => await toast.present());

            this.router.navigateByUrl('/');
          },
          () => {
            this.isResetting = false;
            this.form.enable();
          }
        );
    } catch (error: any) {

      this.toastController.create({message: error.message}).then(async toast => await toast.present());
    }
  }

  private validateForm(): void {
    if (
      this.form.hasError('required', 'password') ||
      this.form.hasError('required', 'passwordConfirmation')
    ) {
      throw new Error($localize`:@@RESET_PASSWORD_PAGE.ERROR_REQUIRED_FIELD`);
    }

    if (
      this.form.hasError('minlength', 'password') ||
      this.form.hasError('minlength', 'passwordConfirmation')
    ) {
      throw new Error($localize`:@@RESET_PASSWORD_PAGE.ERROR_MIN_LENGTH`);
    }

    if (this.form.errors) {
      throw new Error($localize`:@@RESET_PASSWORD_PAGE.ERROR_PASSWORD_MISMATCH`);
    }
  }

  public get token(): string | undefined {
    return this.activatedRoute.snapshot.queryParams[RESET_PASSWORD_TOKEN_PARAM_NAME];
  }
}
