import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { map, take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RecaptchaComponent } from 'ng-recaptcha';
import { TfUserPasswordService } from '../services/user-password.service';
import { TfAutoUnsubscribeComponent } from '../auto-unsubscribe-component';
import { $localize } from '@angular/localize/init';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'tf-forgot-password-page',
  styleUrls: ['./forgot-password-page.component.scss'],
  templateUrl: './forgot-password-page.component.html',
})
export class TfForgotPasswordPageComponent extends TfAutoUnsubscribeComponent {
  @ViewChild(RecaptchaComponent)
  public readonly recaptcha!: RecaptchaComponent;

  public form: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    recaptcha: [null, Validators.required],
  });

  public constructor(
    private readonly formBuilder: FormBuilder,
    private readonly toastController: ToastController,
    private readonly userPasswordService: TfUserPasswordService,
    private readonly router: Router
  ) {
    super();
  }

  public onSubmitClick(): void {
    if (this.form.hasError('required', 'recaptcha')) {
      this.getCaptchaResult$().subscribe((success) => {
        if (!success) {

          this.toastController.create({message: $localize`:@@errorCaptchaError:Captcha error`}).then(async toast => await toast.present());

          return;
        }

        setTimeout(() => this.sendMail(), 0);
      });
    } else {
      this.sendMail();
    }
  }

  private getCaptchaResult$(): Observable<boolean> {
    const result$ = this.recaptcha.resolved.pipe(map(Boolean), take(1), takeUntil(this.destroy$$));

    this.recaptcha.execute();

    return result$;
  }

  private sendMail(): void {
    if (this.form.hasError('email', 'email')) {

      this.toastController.create({message: $localize`:@@errorEmailInvalid:Error emails invalid`}).then(async toast => await toast.present());
      return;
    }

    this.userPasswordService
      .sendResetLink$(this.form.value)
      .pipe(takeUntil(this.destroy$$))
      .subscribe(
        () => {
          const REDIRECT_TIMEOUT = 3000;

          this.toastController.create({message: $localize`:@@successfulAttempt:Successful attempt`}).then(async toast => await toast.present());

          setTimeout(() => this.router.navigateByUrl('/'), REDIRECT_TIMEOUT);
        },
        () => {
          this.form.setErrors({ badForgotAttempt: true });
        }
      );
  }

  protected readonly window = window;
}
