import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {ENV_TOKEN} from "../app.constants";
import {IEnvironment} from "../types";

@Injectable({
  providedIn: 'root'
})
export class TfAccessTokenHttpService {
  public constructor(private readonly http: HttpClient, @Inject(ENV_TOKEN) private readonly env: IEnvironment) {}

  public create$(
    credentials: { email: string; password: string },
    rememberMe: boolean
  ): Observable<string> {
    const body = {
      user: {
        ...credentials,
        rememberMe,
      },
    };

    return this.http
      .post<{ redirect_uri: string }>('api/v2/login', body, { withCredentials: true })
      .pipe(map((data) => data.redirect_uri));
  }

  public delete$(): Observable<void> {
    return this.http.delete<void>('api/v2/logout');
  }

  public getAuthCode$(codeChallenge?: string): Observable<{status: string; redirect_uri: string }> {
    const body = {
      clientId: this.env.authClientId,
      redirectUri: this.env.authRedirectUri,
      codeChallenge,
      responseType: "code",
      codeChallengeMethod: "S256",
      scope: "user",
    };

    return this.http
      .post<{status: string; redirect_uri: string }>('api/v3/mobile/oauth/authorize', body, { withCredentials: true });
  }
}
