<h1 class="title" i18n="@@loginPageTitle">Bienvenue</h1>
<ion-text class="description" i18n="@@loginPageDescription">Merci de vous connecter à votre compte</ion-text>
<form [formGroup]="form" (ngSubmit)="login()">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item mode="ios">
          <ion-label id="login" position="fixed" mode="ios" i18n="@@loginPageEmail">Identifiant</ion-label>
          <ion-input placeholder="example@mail.com" formControlName="email" type="email" mode="ios" inputmode="email"
                     required="true" clear-input="true" aria-labelledby="login" (ionInput)="resetInvalidLoginError()"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-item mode="ios">
          <ion-label id="password" position="fixed" mode="ios" i18n="@@loginPagePassword">Mot de passe</ion-label>
          <ion-input placeholder="******" formControlName="password" type="password" mode="ios" required="true"
                     aria-labelledby="password" (ionInput)="resetInvalidLoginError()">
            <ion-input-password-toggle color="medium" slot="end"></ion-input-password-toggle>
          </ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="forgot-password">
        <a [href]="FORGOT_PASSWORD_PATH" i18n="@@loginPageForgotPassword">Mot de passe oublié ?</a>
      </ion-col>
    </ion-row>

    <ion-row class="ion-align-items-end">
      <ion-col>
        <ion-button expand="block" type="submit" class="ion-margin-top login-button" mode="ios"
                    [disabled]="!form.valid"
                    i18n="@@loginPageSubmitButton">
          Connexion
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
<ion-loading [isOpen]="isLoading" message="We are in the process of authorisation"></ion-loading>
