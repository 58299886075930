import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TfAccessTokenService } from '../services/access-token.service';

@Injectable()
export class TfAccessTokenInterceptor implements HttpInterceptor {
  public constructor(private readonly accessTokenService: TfAccessTokenService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      req.clone({
        setHeaders: { 'Authorization': `Bearer ${this.accessTokenService.value}` },
      })
    );
  }
}
