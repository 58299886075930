<a href="/">
  <ion-chip>
    <ion-icon name="arrow-back-outline"></ion-icon>
    <ion-label i18n="@@resetPasswordBackButton">Retourner</ion-label>
  </ion-chip>
</a>
<h1 class="title" i18n="@@resetPasswordPageTitle">Mot de passe oublié?</h1>
<ion-text class="description" i18n="@@resetPasswordPageDescription">Entrez votre nouveau mot de passe ci-dessous</ion-text>
<form [formGroup]="form" (ngSubmit)="reset()">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item mode="ios">
          <ion-label position="fixed" mode="ios" i18n="@@resetPasswordPagePassword">Mot de passe</ion-label>
          <ion-input placeholder="******" formControlName="password" type="password" mode="ios" required="true"
                     clear-input="true"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-item mode="ios">
          <ion-label position="fixed" mode="ios" i18n="@@resetPasswordPagePasswordConfirmation">Confirmation mot de passe</ion-label>
          <ion-input placeholder="******" formControlName="passwordConfirmation" type="password" mode="ios" required="true"
                     clear-input="true"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row class="ion-align-items-end">
      <ion-col>
        <ion-button expand="block" type="submit" class="ion-margin-top login-button" mode="ios"
                    [disabled]="!form.valid || isResetting"
                    i18n="@@resetPasswordPagePageSubmitButton">
          Confirmer
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>

