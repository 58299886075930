import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
function toSnakeCase(camelCaseStr: string): string {
  if (!camelCaseStr) {
    return '';
  }

  const snakeCaseStr = camelCaseStr.replace(
    /[A-Z]/g,
    (uppercaseChar) => `_${uppercaseChar.toLowerCase()}`
  );

  return snakeCaseStr[0] === '_' ? snakeCaseStr.slice(1) : snakeCaseStr;
}


@Injectable()
export class TfBodyFormatInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let updatedRequest = req;

    if (this.isComplexType(req.body)) {
      const updatedBody =
        req.body instanceof FormData
          ? this.transformFormData(req.body)
          : this.transformObjectKeys(req.body);

      updatedRequest = req.clone({ body: updatedBody });
    }

    return next.handle(updatedRequest);
  }

  private transformFormData(val: FormData): FormData {
    const updatedVal = new FormData();

    val.forEach((value, key) => {
      updatedVal.set(toSnakeCase(key), value);
    });

    return updatedVal;
  }

  private transformObjectKeys(obj: Record<string, any>, nestingCount = 1): Record<string, any> {
    const updatedObj = Array.isArray(obj) ? [] : {};
    const MAX_NESTED_LEVEL = 10;

    if (nestingCount < MAX_NESTED_LEVEL) {
      Object.keys(obj).forEach((key) => {
        if (this.isComplexType(obj[key])) {
          // @ts-ignore
          updatedObj[toSnakeCase(key)] = this.transformObjectKeys(obj[key], nestingCount + 1);
        } else {
          // @ts-ignore
          updatedObj[toSnakeCase(key)] = obj[key];
        }
      });
    } else {
      throw new Error('BodyFormat WARNING: Max nesting data level should nod be greater than 10');
    }

    return updatedObj;
  }

  private isComplexType(val: any): boolean {
    return val instanceof Object;
  }
}
