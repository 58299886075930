import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TfLoginPageComponent } from './login-page/login-page.component';
import { TfForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { TfResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { TfHttpInterceptorsModule } from "./http-interceptors";
import { environment } from "../environments/environment";
import { ENV_TOKEN } from "./app.constants";
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  declarations: [
    AppComponent,
    TfLoginPageComponent,
    TfForgotPasswordPageComponent,
    TfResetPasswordPageComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),

    RecaptchaFormsModule,
    RecaptchaModule,

    TfHttpInterceptorsModule.forRoot(environment.apiUrl),
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recaptchaKey },
    },
    {
      provide: ENV_TOKEN,
      useValue: environment,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
