<ion-app>
  <ion-header class="ion-no-border" mode="ios">
    <ion-img class="logo" src="../assets/images/arturin-logo.svg"></ion-img>
  </ion-header>

  <ion-content class="ion-no-border">
    <ion-toast></ion-toast>
    <ion-loading [isOpen]="!hasContent" message="We are in the process of authorisation"></ion-loading>
    <router-outlet></router-outlet>
  </ion-content>

  <ion-footer class="ion-no-border">
    <ion-text i18n>En vous connectant, vous acceptez nos <a href="https://www.arturin.com/cgs/">Conditions générales de services</a> & <a href="https://www.arturin.com/politique-de-confidentialite/">Politique de confidentialité</a></ion-text>
  </ion-footer>
</ion-app>
