import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TfBaseUrlInterceptor } from './base-url.interceptor';
import { TfAccessTokenInterceptor } from './access-token.interceptor';
import { TfParamsFormatInterceptor } from './params-format.interceptor';
import { TfBodyFormatInterceptor } from './body-format.interceptor';
import { API_URL_TOKEN } from "../app.constants";

@NgModule()
export class TfHttpInterceptorsModule {
  public static forRoot(apiUrlToken: string): ModuleWithProviders<TfHttpInterceptorsModule> {
    return {
      ngModule: TfHttpInterceptorsModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TfBaseUrlInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TfAccessTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TfParamsFormatInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TfBodyFormatInterceptor, multi: true },
        { provide: API_URL_TOKEN, useValue: apiUrlToken },
      ],
    };
  }
}
