<a href="/">
  <ion-chip>
    <ion-icon name="arrow-back-outline"></ion-icon>
    <ion-label>Retourner</ion-label>
  </ion-chip>
</a>
<h1 class="title" i18n="@@forgotPasswordPageTitle">Mot de passe oublié?</h1>
<ion-text class="description" i18n="@@forgotPasswordPageDescription">Entrez votre e-mail ci-dessous et nous vous
  enverrons le lien de réinitialisation
</ion-text>
<form [formGroup]="form" (ngSubmit)="onSubmitClick()">
  <re-captcha #recaptcha="reCaptcha"
              formControlName="recaptcha"
              size="invisible">
  </re-captcha>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item mode="ios">
          <ion-label position="fixed" mode="ios" i18n="@@forgotPasswordPageEmail">Identifiant</ion-label>
          <ion-input placeholder="example@mail.com" formControlName="email" type="email" mode="ios" inputmode="email"
                     required="true" clear-input="true" aria-label="Identifiant"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row class="ion-align-items-end">
      <ion-col>
        <ion-button expand="block" type="submit" class="ion-margin-top login-button" mode="ios"
                    [disabled]="form.hasError('required', 'email')"
                    i18n="@@forgotPasswordPageSubmitButton">
          Envoyer le lien de réinitialisation
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>

<ion-text>Ce site est protégé par reCAPTCHA et les <a href='https://policies.google.com/privacy'>règles de
  confidentialité</a> et les <a href='https://policies.google.com/terms'>conditions d’utilisation</a> de Google
  s’appliquent.
</ion-text>
