import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL_TOKEN } from "../app.constants";

@Injectable()
export class TfBaseUrlInterceptor implements HttpInterceptor {
  public constructor(@Inject(API_URL_TOKEN) private readonly apiUrl: string) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      req.clone({
        url: `${this.apiUrl}${req.url}`,
      })
    );
  }
}
