import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TfLoginPageComponent } from './login-page/login-page.component';
import { TfForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { canActivateApp } from './guards/access.guard';
import { ROUTE_PATHS } from './app.constants';
import { TfResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import { canActivateResetPassword } from './guards/reset-password-page.guard';
import { canLogout } from "./guards/logout.guard";
import { LogoutComponent } from "./logout/logout.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: TfLoginPageComponent,
    canActivate: [canActivateApp],
  },
  {
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    component: TfForgotPasswordPageComponent,
    canActivate: [canActivateApp],
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD,
    component: TfResetPasswordPageComponent,
    canActivate: [canActivateApp, canActivateResetPassword],
  },
  {
    path: ROUTE_PATHS.LOGOUT,
    component: LogoutComponent,
    canActivate: [canLogout],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
