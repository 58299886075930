import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpParameterCodec } from '@angular/common/http';

class MppHttpParameterEncoder implements HttpParameterCodec {
  public encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  public encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  public decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  public decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}


function toSnakeCase(camelCaseStr: string): string {
  if (!camelCaseStr) {
    return '';
  }

  const snakeCaseStr = camelCaseStr.replace(
    /[A-Z]/g,
    (uppercaseChar) => `_${uppercaseChar.toLowerCase()}`
  );

  return snakeCaseStr[0] === '_' ? snakeCaseStr.slice(1) : snakeCaseStr;
}

@Injectable()
export class TfParamsFormatInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req.clone({ params: this.getUpdatedParams(req.params) }));
  }

  private getUpdatedParams(params: HttpParams): HttpParams {
    const updatedParams = {};

    params.keys().forEach((paramKey) => {
      // @ts-ignore
      updatedParams[toSnakeCase(paramKey)] = params.getAll(paramKey);
    });

    return new HttpParams({ encoder: new MppHttpParameterEncoder(), fromObject: updatedParams });
  }
}
