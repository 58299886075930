import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  RouterStateSnapshot
} from '@angular/router';
import { RESET_PASSWORD_TOKEN_PARAM_NAME } from '../app.constants';

export const canActivateResetPassword: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return !!route.queryParams[RESET_PASSWORD_TOKEN_PARAM_NAME];
};

export const canActivateResetPasswordChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => canActivateResetPassword(route, state);

